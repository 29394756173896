import "./App.css";
import { ParallaxProvider } from "react-scroll-parallax";
import Home from "./components/home";
import WhoWeAre from "./components/whoWeAre";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout";
import About from "./components/about";
import Services from "./components/services";
import Clients from "./components/clients";
import PrivacyPolicy from "./components/privacyPolicy";
import Contact from "./components/contact";

function App() {
  return (
    <ParallaxProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="who-we-are" element={<WhoWeAre />} />
            <Route path="about" element={<About />} />
            <Route path="clients" element={<Clients />} />
            <Route path="contact" element={<Contact />} />
            <Route path="services" element={<Services />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ParallaxProvider>
  );
}

export default App;
