import { Link } from "react-router-dom";

const Footer = () => (
  <>
    <div className="flex-1"></div>
    <footer className="bg-gray-100 text-gray-700 w-full mt-4">
      <div className="container mx-auto px-5 py-3 text-xs lg:text-sm flex flex-col md:flex-row items-start">
        <p className="flex-1">
          <strong>Mitchell Rights Management Ltd.</strong>
          <br />
          Richmond House
          <br />
          Walkern Road
          <br />
          Stevenage
          <br />
          Herts
          <br />
          United Kingdom
          <br />
          SG1 3QP
        </p>
        <p className="py-2">
          <strong>Telephone</strong> (+44) 7779 723450 <br />
          <strong>E-Mail</strong>{" "}
          <a href="mailto://enquiries@mitchellrightsmanagement.com">
            enquiries@mitchellrightsmanagement.com
          </a>
          <br />
          <Link to="/privacy">Privacy Policy</Link>
        </p>
      </div>
    </footer>
  </>
);
export default Footer;
