import { useEffect, useRef, useState } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  useParallax,
} from "react-scroll-parallax";

const About = () => {
  const targetRef = useRef();
  const { ref } = useParallax({ translateX: [10, 0] });

  const [targetElement, setElement] = useState();
  useEffect(() => {
    setElement(targetRef.current);
  }, []);

  return (
    <>
      <section className="">
        <div className="container mx-auto p-5 text-lg text-justify relative">
          <section className="container mx-auto mb-8">
            <h1 className="text-[4em] font-header text-indigo-900 mt-4">
              About
            </h1>
          </section>
          <img
            ref={ref}
            src="images/pexels-sora-shimazaki-5673488.jpg"
            className="float-right w-1/2 m-3 object-cover h-96"
          ></img>
          <h1 className="text-2xl font-semibold mb-2 text-amber-600">
            Additional Copy #1
          </h1>

          <p className="p-3">
            Are you a successful writer looking to take your earnings to the
            next level? Look no further than Mitchell Rights Management! Our
            cutting-edge rights management and literary agency services are
            designed to put <strong>you</strong> at the center of the earnings
            and negotiation processes.
          </p>
          <p className="p-3">
            We believe that the traditional procedures for collecting royalties
            and tracking book sales just don't cut it when it comes to
            maximizing author earnings. That's why we've developed a
            revolutionary new system that puts control back where it belongs: in{" "}
            <strong>your</strong> hands.
          </p>
          <h1 className="text-2xl font-semibold mb-2 text-amber-600">
            Additional Copy #2
          </h1>
          <p className="p-3">
            Do you feel confident that you're getting the most out of your
            publishing contracts? Are you equipped with all the sales data you
            need to negotiate from a position of strength? Are your books
            reaching the widest possible audience and generating maximum
            revenue?
          </p>
          <p className="p-3">
            At Mitchell Rights Management, we're here to help you answer{" "}
            <strong>yes</strong> to these critical questions. Our suite of
            services is designed specifically for successful authors like you,
            ensuring that you're getting the most out of your publishing
            partnerships and maximizing your earnings. Let us help you take
            control of your publishing career and achieve the success you
            deserve.
          </p>
        </div>
      </section>
    </>
  );
};

export default About;
