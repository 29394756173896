import {
  faBarChart,
  faFileContract,
  faGlobe,
  faHardDrive,
  faLaptop,
  faPencil,
  faPeopleGroup,
  faPoundSign,
} from "@fortawesome/free-solid-svg-icons";
import ServiceBox from "./common/serviceBox";
import SectionHeader from "./common/sectionHeader";
import PageHeading from "./common/pageHeading";

const Services = () => (
  <>
    <div className="container mx-auto relative p-2">
      <PageHeading>Services</PageHeading>
      <section className="space-y-5 text-xs lg:text-base 2xl:text-lg text-justify ">
        <p>
          We offer a personalized system and services that cater to the unique
          needs of each of our clients.
        </p>
        <p>
          Our system provides authors with a comprehensive understanding of
          their book sales and earnings, allowing you to ensure you receive
          accurate royalty and advance payments based on verified sales data and
          contracted agreements.
        </p>
        <p>
          Our current clients have seen impressive results using our system and
          services, including substantial historical underpayment reclamation,
          increased worldwide title availability, and vastly improved future
          earnings per unit.
        </p>
        <img
          src="images/pexels-pixabay-265087.jpg"
          className="md:float-right md:mt-3 md:ml-12 object-cover object-left md:w-[32rem] md:h-[20rem]"
        ></img>
        <p>Just take a look at our success stories:</p>
        <ul className="list-disc ml-9 space-y-3">
          <li>
            Our clients have experienced over a 30% increase in average earnings
            per unit sold after just three years of working with us
          </li>
          <li>
            Within one year of working with MRM, one of our multi-million
            selling authors saw their title availability more than double
            worldwide
          </li>
          <li>
            We have repeatedly recovered substantial payment errors on behalf of
            our clients
          </li>
        </ul>
        <p>
          At Mitchell Rights Management, we're dedicated to helping authors
          maximize their earnings and achieve their goals.
        </p>
      </section>
      <section className="text-xs lg:text-base 2xl:text-lg">
        <SectionHeader>Our Services Include</SectionHeader>
        <ul className="grid md:grid-cols-2 gap-3">
          <ServiceBox icon={faHardDrive}>
            Database construction and population
          </ServiceBox>
          <ServiceBox icon={faFileContract}>Contract administration</ServiceBox>
          <ServiceBox icon={faBarChart}>
            Sales report generation and interpretation
          </ServiceBox>
          <ServiceBox icon={faLaptop}>
            Precise and highly detailed sales data analysis and reporting tools
          </ServiceBox>
          <ServiceBox icon={faPeopleGroup}>
            Contract administration, negotiation and consultancy
          </ServiceBox>
          <ServiceBox icon={faGlobe}>New market development</ServiceBox>
          <ServiceBox icon={faPoundSign}>
            Payment collection and bank statement reconciliation
          </ServiceBox>
          <ServiceBox icon={faPencil}>
            A wide range of audit services, including tax specialisms
          </ServiceBox>
        </ul>
      </section>
    </div>
  </>
);

export default Services;
