const ProfileBox = ({ image, name, title, children }) => (
  <div className="border m-2 bg-white flex flex-col overflow-hidden">
    <img
      src={image}
      className="object-cover object-middle mx-20 sm:h-64 sm:mx-0 md:h-68 lg:h-76 xl:h-84 2xl:h-96 mb-2"
    ></img>
    <div className="px-3">
      <h3 className="text-indigo-800 font-semibold">{name}</h3>
      <span className="text-amber-600">{title}</span>
    </div>
    <div className="p-3 space-y-1 text-xs lg:text-xs 2xl:text-sm ">
      {children}
    </div>
  </div>
);

export default ProfileBox;
