import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NavBarLink from "./common/navBarLink";
import { faBars, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import AnimateHeight from "react-animate-height";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div className="w-full bg-indigo-900">
      <div className="container mx-auto flex px-5">
        <header className="text-base lg:text-2xl flex-1 flex items-center">
          <div className=" w-14 h-14 p-1.5 bg-white rounded-full">
            <img src="images/mrm-logo-flat.svg" className="object-cover"></img>
          </div>
          <span className="py-5 px-2 text-xs lg:text-base 2xl:text-lg text-white">
            Mitchell Rights Management
          </span>
        </header>
        <nav className="p-5">
          <ul className="hidden md:flex space-x-2 lg:space-x-4 2xl:space-x-8 text-xs lg:text-base 2xl:text-xl">
            <NavBarLink to="/" name="Home" />
            <NavBarLink to="/services" name="Services" />
            {/* <NavBarLink to="/system" name="System" /> */}
            <NavBarLink to="/clients" name="Clients" />
            <NavBarLink to="/who-we-are" name="Who We Are" />
            {/* <NavBarLink to="/about" name="About" /> */}
            {/* <NavBarLink to="/faq" name="FAQ" /> */}
            <NavBarLink to="/contact" name="Contact" />
          </ul>
          <ul className="md:hidden">
            <a onClick={handleShowMenu} className="cursor-pointer">
              <FontAwesomeIcon
                icon={showMenu ? faChevronUp : faBars}
                className="text-2xl text-indigo-200"
              />
            </a>
          </ul>
        </nav>
      </div>
      <AnimateHeight duration={300} height={showMenu ? "auto" : 0}>
        <ul className="md:hidden text-base px-5 mb-2 overflow-hidden ">
          <NavBarLink to="/" name="Home" />
          <NavBarLink to="/services" name="Services" />
          <NavBarLink to="/clients" name="Clients" />
          <NavBarLink to="/who-we-are" name="Who We Are" />
          {/* <NavBarLink to="/about" name="About" /> */}
          {/* <NavBarLink to="/faq" name="FAQ" /> */}
          <NavBarLink to="/contact" name="Contact" />
        </ul>
      </AnimateHeight>
    </div>
  );
};

export default Header;
