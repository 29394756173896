const Testimonial = ({ name, subtitle, children }) => (
  <div className="text-base lg:text-lg 2xl:text-2xl">
    <div className="text-left space-y-6">{children}</div>
    <div className="border-t-2 border-amber-600 text-right font-semibold text-indigo-800 px-6 pt-3 flex flex-col">
      <span>- {name}</span>
      {subtitle && (
        <span className="text-sm xl:text-base 2xl:text-lg text-amber-600">
          {subtitle}
        </span>
      )}
    </div>
  </div>
);

export default Testimonial;
