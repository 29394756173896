import PageHeading from "./common/pageHeading";
import ProfileBox from "./common/profileBox";

const WhoWeAre = () => {
  return (
    <>
      <section className="container w-full md:w-[65%] lg:w-3/4 2xl:w-1/2 mx-auto px-2">
        <PageHeading>Who We Are</PageHeading>
      </section>
      <section className="container mx-auto w-full md:w-[65%] lg:w-3/4 2xl:w-1/2 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-center content-center items-stretch">
        <ProfileBox
          image="images/Alan.jpg"
          name="Alan Mitchell"
          title="Managing Director"
        >
          <p>
            As a founding member of Mitchell Rights Management, Alan is clear
            thinking and direct speaking, and enjoys nothing more than unpacking
            a complex problem and distilling the findings into clear and logical
            next step actions for clients. Alan is the first point of contact
            for new clients and leads on the Jeffrey Archer account.
          </p>
          <p>
            Prior to establishing MRM in 2016, Alan worked for 15 years for
            several international publishing companies.
          </p>
          <p>
            Outside of work, Alan enjoys watching football, reading fiction,
            cooking for his family of five and being taken for walks by his
            Labrador, Milo.
          </p>
        </ProfileBox>
        <ProfileBox
          image="images/Brian.jpg"
          name="Brian Mitchell"
          title="Chair & Special Projects Director"
        >
          <p>
            A qualified Chartered Management Accountant and founding member of
            MRM, Brian is extremely passionate about ensuring Authors rights are
            fully maximised and developed. Described in a Guardian article as
            ‘affectionately known as the Rottweiler’, Brian is intensely loyal
            and committed to any client he takes on.
          </p>
          <p>
            Prior to founding MRM, Brian worked as Finance Director for Ken
            Follett for 15 years.
          </p>
          <p>
            Outside of his role, Brian enjoys all aspects of horse racing and
            most aspects of gardening. He is a long-suffering West Ham fan.
          </p>
        </ProfileBox>
        <ProfileBox
          image="images/Kieran.jpg"
          name="Kieran Allen"
          title="IT Director"
        >
          <p>
            As a lifelong explorer of all aspects of technology, Kieran leads
            all coding development of our systems and services. Kieran is a
            brutally logical thinker, who likes nothing more than finding a
            robust solution that covers all possible edge-cases.
          </p>
          <p>
            Prior to founding MRM, Kieran completed an M.Sci in Marine Biology
            at the University of Southampton.
          </p>
          <p>
            Outside of his role, Kieran loves video games from across the ages,
            experimenting with vegan cookery and is something of a movie
            connoisseur.
          </p>
        </ProfileBox>
        <ProfileBox
          image="images/Chris.jpg"
          name="Chris Mitchell"
          title="Development Director"
        >
          <p>
            Chris joined MRM in 2019. He loves to focus on developing clear
            systems and processes. In addition to this he works very closely
            with Lucinda Riley Ltd. to support their worldwide rights
            management.
          </p>
          <p>
            Prior to joining MRM Chris worked for 25 years in education, being
            the Headteacher of two successful Primary Schools for 15 years of
            this.
          </p>
          <p>
            Outside of his role, Chris enjoys running, especially in beautful
            places.
          </p>
        </ProfileBox>
        <ProfileBox
          image="images/Dale.jpg"
          name="Dale Donoghue"
          title="Rights and Finance Director"
        >
          <p>
            A qualified Chartered Accountant, Dale is extremely experienced in
            both strategic financial advice, accountancy and support, and
            worldwide rights development and management.
          </p>
          <p>
            Prior to joining MRM, Dale worked as Company Accountant and Rights
            manager for internationally bestselling author Ken Follett for 8
            years.
          </p>
          <p>
            Outside of his role, Dale enjoys playing golf and supporting
            Arsenal.
          </p>
        </ProfileBox>
      </section>
    </>
  );
};

export default WhoWeAre;
