import { NavLink } from "react-router-dom";

const NavBarLink = ({ to, name }) => (
  <li>
    <NavLink
      to={to}
      className={({ isActive, isPending }) => {
        if (isActive) return "text-amber-400 underline";
        if (isPending) return "text-indigo-800 hover:text-indigo-800";
        return "text-indigo-200 hover:text-amber-400";
      }}
    >
      {name}
    </NavLink>
  </li>
);

export default NavBarLink;
