import PageHeading from "./common/pageHeading";
import SectionHeader from "./common/sectionHeader";

const PrivacyPolicy = () => (
  <div className="container mx-auto p-2 relative">
    <PageHeading>Privacy Policy</PageHeading>
    <section className="space-y-5 text-xs lg:text-sm 2xl:text-base text-justify ">
      <p>
        At Mitchell Rights Management Ltd, we are committed to protecting your
        privacy and ensuring that your personal information is collected, used,
        and disclosed only in accordance with applicable laws and this Privacy
        Policy. This policy outlines our practices regarding the collection,
        use, and disclosure of your personal information.
      </p>
      <SectionHeader>Collection of Personal Information</SectionHeader>
      <p>
        We may collect personal information from you when you visit our website
        or use our services. This may include your name, email address, phone
        number, address, and other contact information. We may also collect
        information about your use of our website, such as your IP address, the
        type of browser you use, and the pages you visit.
      </p>

      <SectionHeader>Use of Personal Information</SectionHeader>
      <p>
        We may use your personal information to provide you with our services,
        respond to your inquiries, process your payments, and communicate with
        you. We may also use your information to improve our website and
        services, and to comply with legal obligations.
      </p>

      <SectionHeader>Disclosure of Personal Information</SectionHeader>
      <p>
        We may disclose your personal information to our employees, contractors,
        and service providers who need to know the information in order to
        provide our services to you. We may also disclose your information to
        third parties if required by law, or if you have given us your consent
        to do so.
      </p>

      <SectionHeader>Security of Personal Information</SectionHeader>
      <p>
        We take reasonable measures to protect your personal information from
        unauthorized access, use, or disclosure. We use industry-standard
        security measures such as encryption and firewalls to protect your
        information.
      </p>

      <SectionHeader>Retention of Personal Information</SectionHeader>
      <p>
        We will retain your personal information for as long as necessary to
        fulfill the purposes for which it was collected, unless a longer
        retention period is required or permitted by law.
      </p>

      <SectionHeader>
        Access to and Correction of Personal Information
      </SectionHeader>
      <p>
        You may request access to the personal information we have about you,
        and you may request that we correct or delete any inaccurate
        information. To do so, please contact us using the contact information
        provided below.
      </p>

      <SectionHeader>Changes to this Privacy Policy</SectionHeader>
      <p>
        We reserve the right to modify this Privacy Policy at any time, without
        notice to you. Any changes to this policy will be effective immediately
        upon posting on our website.
      </p>

      <SectionHeader>Contact Us</SectionHeader>
      <p>
        If you have any questions or concerns about this Privacy Policy or our
        privacy practices, please contact us at:
      </p>
      <p>privacy@mitchellrightsmanagement.com</p>

      <p>Last updated: April 24, 2023</p>
    </section>
  </div>
);

export default PrivacyPolicy;
