import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PageHeading from "./common/pageHeading";
import {
  faBoltLightning,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const demoRequest = function () {
  const emailBody =
    "Dear Mitchell Rights Management,\nI am interested in arranging a live demonstration of the MRM System.\nKind regards,";
  const mailToLink =
    "mailto:enquiries@mitchellrightsmanagement.com?subject=MRM System Demonstration&body=" +
    encodeURIComponent(emailBody);

  window.location.href = mailToLink;
};

const Contact = () => (
  <>
    <div className="container mx-auto relative p-2">
      <PageHeading>Contact</PageHeading>
      <section className="space-y-5 text-base lg:text-lg 2xl:text-2xl text-justify ">
        <p>
          For an initial exploratory conversation, and to find out more about
          our services and clients, please contact our enquiries department
        </p>
        <div>
          <div className="bg-indigo-800 text-amber-300 text-center px-3 py-1 rounded-t-md lg:w-3/4 block sm:hidden">
            Telephone
          </div>
          <div className="flex border rounded-t-none rounded-b-md sm:rounded-t-md overflow-hidden lg:w-3/4 mx-auto">
            <div className="bg-indigo-800 text-amber-300 p-4 hidden sm:block sm:w-32 md:w-40 lg:w-48 text-right">
              Telephone
            </div>
            <div className="text-indigo-800 p-4 text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
              <FontAwesomeIcon icon={faPhone} /> (+44) 7779 723450
            </div>
          </div>
        </div>
        <div>
          <div className="bg-indigo-800 text-amber-300 text-center px-3 py-1 rounded-t-md lg:w-3/4 block sm:hidden">
            E-Mail
          </div>
          <div className="flex border rounded-t-none rounded-b-md sm:rounded-t-md m-auto lg:w-3/4 overflow-hidden">
            <div className="bg-indigo-800 text-amber-300 p-4 hidden sm:block sm:w-32 md:w-40 lg:w-48 text-right">
              E-Mail
            </div>
            <div className="text-indigo-800 p-4 text-sm md:text-base lg:text-lg xl:text-xl 2xl:text-2xl">
              <FontAwesomeIcon icon={faEnvelope} />{" "}
              <a
                href="mailto:enquiries@mitchellrightsmanagement.com"
                className="text-indigo-600 hover:underline hover:text-indigo-400"
              >
                enquiries@mitchellrightsmanagement.com
              </a>
            </div>
          </div>
        </div>
        <div className="text-center">
          <button
            className="rounded-md bg-indigo-50 border border-indigo-200 text-indigo-600 cursor-pointer p-4 hover:bg-indigo-100 hover:text-indigo-800"
            onClick={() => demoRequest()}
          >
            <FontAwesomeIcon icon={faBoltLightning} /> Request A Live System
            Demonstration
          </button>
        </div>
      </section>
    </div>
  </>
);

export default Contact;
