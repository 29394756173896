import { Outlet } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";

const Layout = () => {
  return (
    <div className="font-display flex flex-col min-h-screen relative">
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
};

export default Layout;
