import { useEffect, useRef, useState } from "react";
import {
  Parallax,
  ParallaxBanner,
  ParallaxBannerLayer,
  useParallax,
} from "react-scroll-parallax";
import Testimonial from "./common/testimonial";
import SectionHeader from "./common/sectionHeader";

const Home = () => {
  return (
    <>
      <section className="container mx-auto flex items-center">
        <img
          src="images/mrm-logo-flat.svg"
          className="object-cover w-16 md:w-28 lg:w-40 m-4"
        ></img>
        <div className="relative flex-1">
          <h1 className="text-[1.3em] md:text-[2.5em] lg:text-[3em] 2xl:text-[4em] font-header -mt-3 text-indigo-900">
            Mitchell Rights Management
          </h1>
          <h2 className="text-[0.8em] md:text-sm lg:text-lg 2xl:text-2xl text-amber-600 lg:-mt-2 2xl:-mt-3 ml-4 absolute">
            Managing, maintaining, and maximising the writer's rights
          </h2>
        </div>
      </section>
      <section>
        <div className="container mx-auto p-2 text-sm lg:text-lg 2xl:text-2xl text-justify relative space-y-5 mb-6">
          <p>
            Are you a successful writer who's tired of feeling like you're not
            getting the most out of your publishing partnerships? Do you long
            for greater control over your earnings and the negotiation process?
            Look no further than Mitchell Rights Management, your partner in
            rights management and literary agency work.
          </p>
        </div>
        <ParallaxBanner className="h-72 md:h-96">
          <ParallaxBannerLayer
            image="images/pexels-pixabay-159711.jpg"
            speed={-20}
          />
        </ParallaxBanner>
        <div className="container mx-auto p-2 text-xs lg:text-base 2xl:text-lg text-justify relative space-y-5 mt-6">
          <p>
            Our mission is simple: to help successful writers like you maximize
            your earnings and take control of your publishing career. We
            understand that the traditional procedures for collecting royalties
            and tracking book sales simply don't cut it in today's fast-paced,
            ever-changing publishing landscape. That's why we've developed a
            revolutionary new system and services that put YOU at the centre of
            the earnings and negotiation process.
          </p>
          <p>
            With our suite of services, you'll be able to access all the sales
            data you need to negotiate from a position of strength. Our team of
            experts will work tirelessly to ensure that your work is being made
            available to the widest possible audience, generating maximum
            revenue for you in the process. And with our state-of-the-art rights
            management platform, you'll have full control over the negotiation
            and earnings process.
          </p>
          <p>
            At Mitchell Rights Management, we believe that successful writers
            deserve nothing but the best. That's why we're dedicated to
            providing you with the tools and support you need to achieve the
            success you deserve. So why settle for less? Choose Mitchell Rights
            Management and maximise your writing career today!
          </p>
          <SectionHeader>Testimonials</SectionHeader>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 md:gap-12">
            <div className="md:col-span-2">
              <Testimonial name="Jeffrey Archer">
                <p>
                  "Working with Mitchell Rights Management has given me a much
                  clearer picture of my sales, my contract coverage and my
                  earnings. They are changing the way that royalty data is
                  stored, analysed and utilised.
                </p>
                <p>
                  Any writer who has an interest in maximising their rights -
                  and especially their foreign language rights - should get in
                  touch with MRM."
                </p>
              </Testimonial>
            </div>
            <div></div>
            <div></div>
            <div className="md:col-span-2">
              <Testimonial
                name="Stephen Riley"
                subtitle="Agent for Sunday Times No.1 best-selling author Lucinda Riley"
              >
                <p>
                  "MRM are revolutionising rights management - particularly for
                  foreign rights which are, in an increasingly global market,
                  demanding more attention than traditionally received.
                </p>
                <p>
                  MRM have created a first class control system for Lucinda
                  Riley which has resulted in significant additional royalty
                  payments from a number of territories. Very highly
                  recommended."
                </p>
              </Testimonial>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
