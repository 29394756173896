import ClientBox from "./common/clientBox";
import PageHeading from "./common/pageHeading";
import SectionHeader from "./common/sectionHeader";

const Clients = () => {
  return (
    <>
      <section className="container mx-auto px-2">
        <PageHeading>Our Clients</PageHeading>
        <p className="text-xs lg:text-base 2xl:text-lg">
          We value client privacy and confidentiality, and while we have
          provided a list of some past and current clients, it is not
          exhaustive. Some clients choose to remain confidential.
        </p>
      </section>
      <section className="container mx-auto mb-10 px-2 text-sm md:text-base lg:text-xl">
        <SectionHeader>Authors</SectionHeader>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 items-stretch mb-12">
          {/* <a href="https://www.jeffreyarcher.com" target="_blank">
            <div className="bg-black flex flex overflow-hidden px-4 py-3 rounded-sm">
              <img
                src="images/jeffrey-archer.jpg"
                className={`rounded-full h-24 w-24 md:h-28 md:w-28 lg:h-32 lg:w-32 ml-6 md:ml-12 object-cover border-white`}
              />
              <div className="flex items-center text-center flex-1 px-4">
                <img
                  src="images/JA-logo.png"
                  className="object-cover mt-1 md:mt-4 ml-4"
                />
              </div>
            </div>
          </a> */}
          <a href="https://www.jeffreyarcher.com" target="_blank">
            <div>
              <img
                src="images/jeffrey-archer-banner.jpg"
                className="object-cover rounded-sm"
              />
            </div>
          </a>
          <a href="https://lucindariley.co.uk" target="_blank">
            <div>
              <img
                src="images/lucinda-riley-ltd.jpg"
                className="object-cover rounded-sm"
              />
            </div>
          </a>
        </div>
        <div className="grid grid-cols-1 xl:grid-cols-2 gap-4 items-stretch">
          <ClientBox
            name="Yasmin Azad"
            image="images/yasmin-azad.jpg"
            link="https://staydaughter.com/"
          />
          <ClientBox
            name="Matt Brolly"
            image="images/matt-brolly.jpg"
            link="https://mattbrolly.com/"
          />
          <ClientBox
            name="Andi Cumbo-Floyd"
            image="images/andi-cumbo-floyd.jpg"
            link="https://andilit.com/"
          />
          <ClientBox
            name="David Donachie"
            image="images/David Donachie.jpg"
            link="https://facebook.com/daviddonachieauthor/"
          />
          <ClientBox
            name={'Johnny "Two Combs" Howard'}
            image="images/johnny-howard.jpg"
          />
          <ClientBox
            name="Ameena Hussein"
            image="images/ameena-hussein.jpg"
            link="https://facebook.com/ameena.hussein.39/"
          />
        </div>
      </section>
      <section className="container mx-auto px-2 text-sm md:text-base lg:text-xl">
        <SectionHeader>Literary Agencies</SectionHeader>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
          <ClientBox
            name="DunnFogg"
            image="images/DunnFogg.png"
            cover={false}
            link="https://dunnfogg.co.uk/"
          />
          <ClientBox
            name="Featherstone Cairns"
            image="images/featherstone-cairns.png"
            imageClass="p-3"
            cover={false}
            link="https://featherstonecairns.com/"
          />
          <ClientBox
            name="Peters, Fraser and Dunlop"
            image="images/pfd.png"
            imageClass="p-2"
            cover={false}
            link="https://petersfraserdunlop.com/"
          />
        </div>
      </section>
    </>
  );
};

export default Clients;
