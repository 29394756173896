const ClientBox = ({
  name,
  image,
  cover = true,
  link = "",
  imageClass = "",
}) => {
  const className = cover ? "object-cover" : "object-contain";
  return (
    <a href={link} target="_blank">
      <div className="bg-white flex items-center h-full overflow-hidden rounded-l-full font-header relative">
        <img
          src={image}
          className={`absolute rounded-full bg-white border-4 border-indigo-900 h-28 w-28 md:h-32 md:w-32 lg:h-36 lg:w-36 ${className} ${imageClass}`}
        ></img>
        <div className="self-end text-center flex-1 h-28 md:h-32 lg:h-36 flex flex-col">
          <h3 className="flex-1 flex items-center text-left text-indigo-900 text-base sm:text-[1.5em] font-semibold p-2 border-indigo-900 rounded-tr-full border-t-2 border-r-2">
            <span className="ml-32 md:ml-36 lg:ml-40">{name}</span>
          </h3>
          <div className="text-center flex items-center bg-indigo-900 rounded-br-lg text-amber-400 justify-end">
            <span className="ml-32 md:ml-36 lg:ml-40 py-1.5 pr-4">
              {link.substring(8, link.length - 1)}
            </span>
          </div>
        </div>
      </div>
    </a>
  );
};

export default ClientBox;
