import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const ServiceBox = ({ icon, children }) => (
  <li className="border flex items-stretch text-left">
    <div className="flex items-center justify-center border-r text-lg lg:font-2xl px-5 text-indigo-800 bg-blue-50 w-14">
      <FontAwesomeIcon icon={icon} />
    </div>
    <span className="px-2 py-3 flex items-center">{children}</span>
  </li>
);

export default ServiceBox;
